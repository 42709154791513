import React from 'react';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const Fallback: React.FC = () => (
  <Container maxWidth="sm">
    <Box marginTop={6}>
      <Typography variant="h4">
        무언가 잘못 됐습니다.
        <br />
        Something went wrong.
      </Typography>
    </Box>
    <Box marginTop={2}>
      <Typography variant="body1">
        문제 해결을 위해 에러 로그가 서버에 전송되었습니다. 원인을 찾아 문제를 해결하도록 하겠습니다.
        <br />
        그때까지는, 한번 새로고침을 해주세요.
        <br />
        <br />
        An error log has been sent for troubleshooting. We will find the cause and fix the problem.
        <br />
        In the meantime, please refresh once.
      </Typography>
    </Box>
    <Box marginTop={2} textAlign="center">
      <Button variant="outlined" color="primary" onClick={() => window.location.reload()}>
        Refresh
      </Button>
    </Box>
  </Container>
);

export default Fallback;
