import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { IoLogoGoogle, IoLogoFacebook } from 'react-icons/io';

import useSnackbar from '../hooks/useSnackbar';
import AuthRepository from '../repositories/authRepository';
import useAnonymousRequired from '../hooks/useAnonymousRequired';

interface SignUpProps {
  authRepository: AuthRepository
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: theme.spacing(6, 0),
  },
  oauth: {
    textTransform: 'none',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignUpContainer: React.FC<SignUpProps> = (props) => {
  const { authRepository } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConf, setPasswordConf] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [setSnackbarMessage, renderSnackbar] = useSnackbar();
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  useAnonymousRequired(authRepository, history);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordError('');
    setPassword(event.target.value);
  };

  const handlePasswordConfChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordError('');
    setPasswordConf(event.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (submitting) return;

    if (password !== passwordConf) {
      setPasswordError(t('checkPassword'));
      return;
    }
    setSubmitting(true);
    try {
      await authRepository.signUp(email, password);
      history.push('/welcome');
    } catch (error) {
      setSnackbarMessage(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSignInWithGoogle = async () => {
    setSubmitting(true);
    try {
      await authRepository.signInWithGoogle();
      history.push('/welcome');
    } catch (error) {
      setSnackbarMessage(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSignInWithFacebook = async () => {
    setSubmitting(true);
    try {
      await authRepository.signInWithFacebook();
      history.push('/welcome');
    } catch (error) {
      setSnackbarMessage(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <Helmet>
        <title>
          {t('cownote')}
          {' - '}
          {t('signUp')}
        </title>
      </Helmet>
      {renderSnackbar()}
      <div className={classes.wrapper}>
        <form onSubmit={handleSubmit}>
          <Typography variant="h4" align="center">
            {t('signUp')}
          </Typography>
          <Box marginTop={3}>
            <Button
              className={classes.oauth}
              variant="outlined"
              color="primary"
              onClick={handleSignInWithGoogle}
              fullWidth
              disabled={submitting}
              startIcon={<IoLogoGoogle />}
            >
              {t('startWithGoogle')}
            </Button>
          </Box>
          <Box marginTop={2}>
            <Button
              className={classes.oauth}
              variant="outlined"
              color="primary"
              onClick={handleSignInWithFacebook}
              fullWidth
              disabled={submitting}
              startIcon={<IoLogoFacebook />}
            >
              {t('startWithFacebook')}
            </Button>
          </Box>
          <Box marginTop={2}>
            <Typography component="div" variant="caption" align="center">
              {t('or')}
            </Typography>
          </Box>
          <TextField
            value={email}
            label={t('email')}
            onChange={handleEmailChange}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            type="password"
            value={password}
            label={t('password')}
            onChange={handlePasswordChange}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            error={passwordError !== ''}
            helperText={passwordError}
            type="password"
            value={passwordConf}
            label={t('confirmPassword')}
            onChange={handlePasswordConfChange}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <Button
            className={classes.submit}
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
          >
            {submitting ? <CircularProgress color="inherit" size={22} /> : t('submitSignUp')}
          </Button>
          <Typography variant="body2">
            {t('signUpAgreement')}
          </Typography>
        </form>
      </div>
    </Container>
  );
};

export default SignUpContainer;
