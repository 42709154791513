import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CreateIcon from '@material-ui/icons/Create';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import EventNoteIcon from '@material-ui/icons/EventNote';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import TimelineIcon from '@material-ui/icons/Timeline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { ListItemIcon } from '@material-ui/core';
import Translation from './Translation';

import AuthRepository from '../repositories/authRepository';
import useUser from '../hooks/useUser';
import * as fp from '../helpers/facebookPixel';
import logoSrc from '../assets/images/logo.png';

interface AppHeaderProps {
  authRepository: AuthRepository
}

const useStyles = makeStyles((theme: Theme) => ({
  logoPanel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  logo: {
    display: 'flex',
    width: 36,
    height: 36,
    borderRadius: 8,
    marginRight: 8,
  },
  navigationPanel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(4),
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  menuList: {
    width: 250,
  },
  userPanel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: 'inherit',
  },
}));

const HeaderContainer: React.FC<AppHeaderProps> = (props) => {
  const { authRepository } = props;
  const user = useUser(authRepository);
  const [menuOpen, setMenuOpen] = useState(false);
  const [accountAnchorRef, setAccountAnchorRef] = useState<HTMLElement | null>(null);
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleSignOut = async () => {
    await authRepository.signOut();
    setAccountAnchorRef(null);
    handleMenuClose();
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAccountAnchorRef(event.currentTarget);
    handleMenuClose();
  };

  const handleAccountClose = () => {
    setAccountAnchorRef(null);
    handleMenuClose();
  };

  const toLogWriter = () => {
    fp.initiateWriting();
    if (history.location.pathname.includes('logs')) {
      history.push('/calendar?writer=open');
      window.location.reload();
    } else {
      history.push('/calendar?writer=open');
    }
  };

  const toProfile = () => {
    history.push('/profile');
    setAccountAnchorRef(null);
    handleMenuClose();
  };

  return (
    <AppBar position="static" elevation={2}>
      <Toolbar variant="regular">
        <Hidden mdUp>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            onClick={() => setMenuOpen(true)}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Box className={classes.logoPanel} marginRight={2}>
          <Link to="/">
            <img className={classes.logo} src={logoSrc} alt="logo" />
          </Link>
          <Link to="/">
            <Box display="flex" alignItems="center">
              <Typography component="span" variant="h6" color="inherit">
                Cownote
              </Typography>
              <Typography style={{ marginLeft: 4 }} component="span" variant="caption" color="inherit">
                beta
              </Typography>
            </Box>
          </Link>
        </Box>
        <div className={classes.navigationPanel}>
          <Hidden smDown>
            <Link to="/calendar">
              {/* <IconButton color="inherit">
                <EventNoteIcon />
              </IconButton> */}
              <Button
                className={classes.menuButton}
                variant="text"
                color="inherit"
                startIcon={<EventNoteIcon />}
              >
                {t('journal')}
              </Button>
            </Link>
            <Link to="/feed">
              {/* <IconButton color="inherit">
                <DynamicFeedIcon />
              </IconButton> */}
              <Button
                className={classes.menuButton}
                variant="text"
                color="inherit"
                startIcon={<DynamicFeedIcon />}
              >
                {t('feed')}
              </Button>
            </Link>
            <Link to="/asset">
              {/* <IconButton color="inherit">
                <TimelineIcon />
              </IconButton> */}
              <Button
                className={classes.menuButton}
                variant="text"
                color="inherit"
                startIcon={<TimelineIcon />}
              >
                {t('asset')}
              </Button>
            </Link>
          </Hidden>
        </div>
        <div className={classes.userPanel}>
          <Translation />
          <IconButton onClick={toLogWriter} color="inherit">
            <CreateIcon />
          </IconButton>
          <Link to="/faq">
            <IconButton color="inherit">
              <HelpOutlineIcon />
            </IconButton>
          </Link>
          <Hidden smDown>
            {user ? (
              <>
                <IconButton onClick={handleMenuClick} color="inherit">
                  <AccountCircleIcon />
                </IconButton>
                <Menu
                  anchorEl={accountAnchorRef}
                  keepMounted
                  open={Boolean(accountAnchorRef)}
                  onClose={handleAccountClose}
                >
                  <MenuItem disabled>{user?.email}</MenuItem>
                  <MenuItem onClick={toProfile}>{t('profile')}</MenuItem>
                  <MenuItem onClick={handleSignOut}>{t('signOut')}</MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <Link to="/sign_in">
                  <Button variant="text" color="inherit">
                    {t('signIn')}
                  </Button>
                </Link>
                <Link to="/sign_up">
                  <Button variant="outlined" color="inherit">
                    {t('signUp')}
                  </Button>
                </Link>
              </>
            )}
          </Hidden>
        </div>
      </Toolbar>
      <Drawer open={menuOpen} anchor="left" onClose={() => setMenuOpen(false)}>
        <div className={classes.menuList}>
          <List>
            <Link to="/calendar">
              <ListItem button onClick={handleMenuClose}>
                <ListItemIcon>
                  <EventNoteIcon />
                </ListItemIcon>
                <ListItemText primary={t('journal')} />
              </ListItem>
            </Link>
            <Link to="/feed">
              <ListItem button onClick={handleMenuClose}>
                <ListItemIcon>
                  <DynamicFeedIcon />
                </ListItemIcon>
                <ListItemText primary={t('feed')} />
              </ListItem>
            </Link>
            <Link to="/asset">
              <ListItem button onClick={handleMenuClose}>
                <ListItemIcon>
                  <TimelineIcon />
                </ListItemIcon>
                <ListItemText primary={t('asset')} />
              </ListItem>
            </Link>
          </List>
          <Divider />
          {user ? (
            <List>
              <ListItem button disabled>
                <ListItemText primary={user.email} />
              </ListItem>
              <ListItem button onClick={toProfile}>
                <ListItemText primary={t('profile')} />
              </ListItem>
              <ListItem button onClick={handleSignOut}>
                <ListItemText primary={t('signOut')} />
              </ListItem>
            </List>
          ) : (
            <List>
              <Link to="/sign_in">
                <ListItem button onClick={handleMenuClose}>
                  <ListItemText primary={t('signIn')} />
                </ListItem>
              </Link>
              <Link to="/sign_up">
                <ListItem button onClick={handleMenuClose}>
                  <ListItemText primary={t('signUp')} />
                </ListItem>
              </Link>
            </List>
          )}
        </div>
      </Drawer>
    </AppBar>
  );
};

export default HeaderContainer;
