import * as firebase from 'firebase';
import union from 'lodash/union';

export interface Preference {
  id?: string;
  userUID: string;
  tags: string[];
  emojis: string[];
  categories: string[];
}

export const defaultPreference: Preference = {
  userUID: '',
  tags: [],
  emojis: ['😭', '😠', '😐', '🙂', '😁'],
  categories: [],
};

export default class PreferenceRepository {
  private db: firebase.firestore.Firestore;

  constructor(db: firebase.firestore.Firestore) {
    this.db = db;
  }

  private collection = () => this.db.collection('preferences') as firebase.firestore.CollectionReference<Preference>;

  public create(pref: Preference) {
    return this.collection().add(pref);
  }

  public async retrieve(userUID: string): Promise<Preference> {
    const query = this.collection().where('userUID', '==', userUID);
    const snapshot = await query.get();
    if (snapshot.empty) {
      return {
        ...defaultPreference,
        userUID,
      };
    }
    const doc = snapshot.docs[0];
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
      emojis: union(defaultPreference.emojis, data.emojis),
      categories: data.categories || defaultPreference.categories,
    };
  }

  public update(id: string, pref: Preference) {
    return this.collection().doc(id).update(pref);
  }

  public createOrUpdate(pref: Preference) {
    if (!pref.id) {
      return this.create(pref);
    }
    return this.update(pref.id, pref);
  }
}
