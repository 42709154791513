// Facebook Pixel
// ref: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking

export const initiateWriting = (option: any = {}) => {
  fbq('trackCustom', 'InitiateWriting', option);
};

export const submitWriting = (option: any = {}) => {
  fbq('trackCustom', 'SubmitWriting', option);
};
