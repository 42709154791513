import koTranslation from './ko/translation';
import enTranslation from './en/translation';

export default {
  ko: {
    translation: koTranslation,
  },
  en: {
    translation: enTranslation,
  },
};
