import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';

import AuthRepository, { User } from '../repositories/authRepository';

interface ProfileProps {
  authRepository: AuthRepository;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: theme.spacing(6, 0),
  },
  content: {
    margin: theme.spacing(3, 0),
  },
  dividerFullWidth: {
    margin: `5px 0 0 ${theme.spacing(2)}px`,
  },
}));

const Profile: React.FC<ProfileProps> = (props) => {
  const { authRepository } = props;
  const [user, setUser] = useState<User | null>(null);
  const [displayName, setDisplayName] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleDialogClose = () => {
    setDisplayName(user?.displayName || '');
    setDialogOpen(false);
  };

  const handleDisplayNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayName(event.target.value);
  };

  const handleDisplayNameUpdate = async () => {
    if (submitting) return;

    setSubmitting(true);
    await authRepository.updateUserDisplayName(displayName);
    setUser(authRepository.getCurrentUser());
    handleDialogClose();
    setSubmitting(false);
  };

  useEffect(() => authRepository.onAuthStateChanged((u) => {
    setUser(u);
    setDisplayName(u?.displayName || '');
  }), [authRepository]);

  return (
    <div className={classes.wrapper}>
      <Container maxWidth="xs">
        <Typography variant="h4" align="center">
          {t('profile')}
        </Typography>
        <div className={classes.content}>
          <Paper elevation={0} variant="outlined">
            <List>
              <li>
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  display="block"
                  variant="caption"
                >
                  {t('email')}
                </Typography>
              </li>
              <ListItem>
                <ListItemText primary={user?.email} />
              </ListItem>
              <Divider component="li" />
              <li>
                <Typography
                  className={classes.dividerFullWidth}
                  color="textSecondary"
                  display="block"
                  variant="caption"
                >
                  {t('name')}
                </Typography>
              </li>
              <ListItem>
                <ListItemText primary={user?.displayName || '-'} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => setDialogOpen(true)}>
                    <EditIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Paper>
        </div>
      </Container>
      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="xs">
        <DialogTitle>{t('changeName')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="normal"
            label={t('name')}
            fullWidth
            variant="outlined"
            value={displayName}
            onChange={handleDisplayNameChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleDisplayNameUpdate} color="primary" variant="outlined">
            {submitting ? <CircularProgress color="inherit" size={22} /> : t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Profile;
