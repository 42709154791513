import * as firebase from 'firebase';
import unionBy from 'lodash/unionBy';

export interface Comment {
  id: number;
  userUID: string;
  userDisplayName?: string;
  body: string;
  createdAt: number; // epoch time
}

export interface MemoItem {
  id: number;
  key: string;
  value: string;
  image?: string;
  category?: string;
}

export type Memo = MemoItem[];

export type Currency = 'USD' | 'KRW' | 'BTC' | '';

export interface Asset {
  id: number;
  name: string; // 자산 이름 (eg. 이더리움, 루나, 테슬라, ...)
  currency: Currency; // 구매 통화
  amount: number | string; // 갯수
  price: number | string; // (현재)가격
  remarks: string;
}

export interface Log {
  id?: string;
  userUID: string;
  userDisplayName?: string;
  date: number; // epoch time
  emoji: string;
  assets?: Asset[];
  memo: Memo;
  comments?: Comment[];
  likes?: number;
  tags?: string[];
  categories?: string[];
  public?: boolean;
  publicAssets?: boolean;
  createdAt: number;
}

export interface ListLogsParams {
  userUID?: string;
  tags?: string[];
  categories?: string[];
  dateFrom?: number; // epoch time
  dateTo?: number; // epoch time
  lastVisible?: number; // epoch time for pagination
  limit?: number;
  onlyPrivate?: boolean;
}

export default class LogRepository {
  private db: firebase.firestore.Firestore;

  constructor(db: firebase.firestore.Firestore) {
    this.db = db;
  }

  private collection = () => this.db.collection('logs') as firebase.firestore.CollectionReference<Log>;

  public async create(log: Log): Promise<Log> {
    const ref = await this.collection().add(log);
    const doc = await ref.get();
    const data = doc.data() as Log;
    return {
      id: doc.id,
      ...data,
    };
  }

  public async retrieve(id: string): Promise<Log> {
    const doc = await this.collection().doc(id).get();
    const data = doc.data() as Log;
    return {
      id: doc.id,
      ...data,
    };
  }

  public delete(id: string) {
    return this.collection().doc(id).delete();
  }

  public update(id: string, log: Log) {
    return this.collection().doc(id).update(log);
  }

  public createOrUpdate(log: Log) {
    if (!log.id) {
      return this.create(log);
    }
    return this.update(log.id, log);
  }

  public async addComment(comment: Comment, log: Log): Promise<Log> {
    const doc = await this.retrieve(log.id!);
    const newDoc: Log = {
      ...doc,
      comments: unionBy(doc.comments, [comment]),
    };
    await this.update(newDoc.id!, newDoc);
    return newDoc;
  }

  public async removeComment(comment: Comment, log: Log): Promise<Log> {
    const doc = await this.retrieve(log.id!);
    const newDoc: Log = {
      ...doc,
      comments: doc.comments?.filter((c) => c.id !== comment.id),
    };
    await this.update(newDoc.id!, newDoc);
    return newDoc;
  }

  public async listLogsWithRefs(listOfRefs: string[]): Promise<Log[]> {
    const query = this.collection().where(firebase.firestore.FieldPath.documentId(), 'in', listOfRefs);
    const snapshot = await query.get();
    return snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  }

  public async listLogsWithDateFilter(params: ListLogsParams): Promise<Log[]> {
    const {
      userUID,
      tags,
      dateFrom,
      dateTo,
    } = params;
    let query = this.collection().where('userUID', '==', userUID);

    if (dateFrom) {
      query = query.where('date', '>=', dateFrom);
    }
    if (dateTo) {
      query = query.where('date', '<=', dateTo);
    }
    if (Number(tags?.length) > 0) {
      query = query.where('tags', 'array-contains-any', tags);
    }

    const snapshot = await query.get();
    return snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  }

  private static pageQuery(
    query: firebase.firestore.Query<Log>,
    startAfter?: number,
    tags?: string[],
    categories?: string[],
    limit = 10,
  ): firebase.firestore.Query<Log> {
    let pagedQuery = query;
    pagedQuery = pagedQuery.orderBy('date', 'desc').limit(limit);
    if (Number(tags?.length) > 0) {
      pagedQuery = pagedQuery.where('tags', 'array-contains-any', tags);
    }
    if (Number(categories?.length) > 0) {
      pagedQuery = pagedQuery.where('categories', 'array-contains-any', categories);
    }
    if (startAfter) {
      pagedQuery = pagedQuery.startAfter(startAfter);
    }
    return pagedQuery;
  }

  public async pageLogsWithLastVisibleCursor(params: ListLogsParams): Promise<Log[]> {
    const {
      userUID,
      tags,
      categories,
      lastVisible,
      limit = 10,
      onlyPrivate,
    } = params;

    const snapshots = [];
    // 인증된 유저의 일
    if (userUID) {
      let privateQuery = this.collection().where('userUID', '==', userUID);
      privateQuery = LogRepository.pageQuery(privateQuery, lastVisible, tags, categories, limit);
      const privateSnapshot = privateQuery.get();
      snapshots.push(privateSnapshot);
    }

    // 공개된 일지
    if (!onlyPrivate) {
      let publicQuery = this.collection().where('public', '==', true);
      publicQuery = LogRepository.pageQuery(publicQuery, lastVisible, tags, categories, limit);
      const publicSnapshot = publicQuery.get();
      snapshots.push(publicSnapshot);
    }

    const resolved = await Promise.all(snapshots);
    const logs = resolved.map((snapshot) => snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })));

    // 중복제거
    const unionLogs = unionBy(logs[0], logs[1], 'id');

    // 날짜순으로 정렬
    const sortedLogs = unionLogs.sort((a, b) => b.date - a.date);

    // 처음 10개만 리턴
    return sortedLogs.slice(0, 10);
  }

  public async pageAllLogsWithLastVisibleCursor(params: ListLogsParams): Promise<Log[]> {
    const {
      tags,
      lastVisible,
      limit = 10,
    } = params;
    let query = this.collection().orderBy('date', 'desc');

    if (Number(tags?.length) > 0) {
      query = query.where('tags', 'array-contains-any', tags);
    }
    if (lastVisible) {
      query = query.startAfter(lastVisible);
    }

    query = query.limit(limit);
    const snapshot = await query.get();
    return snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  }
}
