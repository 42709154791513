import React from 'react';
import { Link } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import { GoLinkExternal } from 'react-icons/go';

import logoSrc from '../assets/images/logo.png';

const useStyle = makeStyles((theme: Theme) => ({
  logo: {
    borderRadius: theme.spacing(2),
    width: 100,
    height: 100,
  },
}));

const Welcome: React.FC = () => {
  const classes = useStyle();

  return (
    <Container maxWidth="sm">
      <Box marginTop={6} marginBottom={6}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <img className={classes.logo} src={logoSrc} alt="logo" />
        </Box>
        <Box marginTop={3} textAlign="center">
          <Typography variant="h4">
            회원가입 완료!
          </Typography>
        </Box>
        <Box marginTop={6}>
          <Typography variant="body1">
            어떻게 일지를 쓰기 시작해야할지 모르겠다면, 시작 가이드를 읽어보세요.
          </Typography>
        </Box>
        <Box marginTop={2}>
          <a href="http://bit.ly/cownote-start-guide" target="blank">
            <Button size="large" color="primary" variant="contained" startIcon={<GoLinkExternal />}>
              가이드 읽으러 가기
            </Button>
          </a>
        </Box>
        <Box marginTop={6}>
          <Typography variant="body1">
            지금 바로 첫 투자일지를 써볼까요?
          </Typography>
        </Box>
        <Box marginTop={2}>
          <Link to="/calendar?writer=open">
            <Button size="large" color="primary" variant="outlined" startIcon={<CreateIcon />}>
              일지 쓰기
            </Button>
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export default Welcome;
