export default {
  cownote: '카우노트',
  cownoteDescription: '카우노트는 여러분이 보다 나은 투자자가 될 수 있도록 돕는 서비스입니다. 더 나은 투자자가 되는 길, 그것은 나의 투자를 잘 기록하고 복기하는 것입니다. 여러분이 좋은 투자자가 될 수 있도록, 카우노트는 좋은 투자일지를 쓰게 돕습니다.',
  write: '쓰기',
  save: '저장',
  cancel: '취소',
  signIn: '로그인',
  signUp: '회원가입',
  doNotHaveAccount: '계정이 없으신가요? 회원가입하기',
  submitSignUp: '가입하기',
  signOut: '로그아웃',
  checkPassword: '비밀번호를 확인해주세요.',
  profile: '프로필',
  submit: '게시',
  add: '추가',
  asset: '자산',
  date: '날짜',
  from: 'From',
  to: 'To',
  writeLog: '일지 작성',
  logs: '일지',
  log: '일지',
  journal: '일지',
  tradingJournal: '투자일지',
  feed: '피드',
  today: '오늘',
  memo: '메모',
  email: '이메일',
  name: '이름',
  password: '비밀번호',
  confirmPassword: '비밀번호 확인',
  or: '또는',
  signInWithGoogle: '구글 계정으로 로그인',
  signInWithFacebook: '페이스북 계정으로 로그인',
  startWithGoogle: '구글 계정으로 시작하기',
  startWithFacebook: '페이스북 계정으로 시작하기',
  section: '항목',
  content: '내용',
  confirmCancel: '정말로 취소하시겠습니까?',
  confirmCancelVerbose: '아직 저장하지 않은 내용이 있습니다. 취소하시겠습니까?',
  confirmDelete: '정말로 삭제하시겠습니까?',
  confirmDeleteVerbose: '삭제한 일지는 복구할 수 없습니다. 삭제하시겠습니까?',
  no: '아니요',
  yes: '예',
  yesAnyway: '예, 상관없어요',
  changeName: '이름 변경',
  latestLog: '최근 일지',
  noLogWritten: '아직 작성한 일지가 없습니다.',
  noBookmarks: '아직 북마크가 없습니다.',
  authRequired: '로그인이 필요합니다.',
  assets: '자산',
  assetName: '자산 이름',
  price: '가격',
  purchasePrice: '매수평단가',
  evaluationAmount: '평가금액',
  amount: '수량',
  currency: '통화',
  termsOfUse: '이용약관',
  privacyPolicy: '개인정보 취급방침',
  signUpAgreement: '회원으로 가입함으로써 이용약관과 개인정보 취급방침에 동의한 것으로 간주합니다.',
  assetsFluctuations: '자산 변동',
  totalAssetsFluctuations: '총 자산 변동',
  expressTodayTrade: '오늘의 투자를 이모티콘으로 표현해보세요',
  faq: '도움말',
  dailyNoteForInvestors: '투자자를 위한 투자일지',
  marketState: '시장상황',
  tradingStatus: '투자현황',
  remarks: '비고',
  assetInfoAlert: '자산에 중요한 변화가 있었군요! 어떤 상황에서, 왜 그러한 변화가 생겼는지 일지에 적어보세요.',
  releaseNote: '업데이트 로그',
  startingGuide: '투자일지 가이드',
  howToStart: '어떻게 써야 하나요?',
  tags: '태그',
  category: '카테고리',
  share: '공유',
  canMakePublic: '일지를 공개해서 다른 사람과 공유할 수 있습니다.',
  allowPublicAccess: '일지 공개',
  allowPublicAccessToAssets: '자산 공개',
  publicAccessInfo: '일지를 공개하면 다른 사람의 피드에 표시되고, 고유한 링크로 일지를 다른 사람과 공유할 수 있습니다.',
  copiedLink: '링크를 복사했습니다.',
  showPrivateOnly: '내 일지만 보기',
  bookmarks: '북마크',
  writeComment: '코멘트 작성',
  about: '소개',
  // FAQ
  faqQ1: '카우노트는 뭐하는 곳인가요?',
  faqA1: '카우노트는 사람들이 더 좋은 투자자로 성장할 수 있도록 돕는 투자일지 서비스입니다. 자신의 투자를 기록하고 투자의 잘못된 점, 잘했던 점을 되짚어보세요.',
  faqQ2: '이건 누가 만든거죠?',
  faqA2: '지나가던 흑우가 만들었습니다. 음메~',
  faqQ3: '왜 만들었나요?',
  faqA3: '저희가 쓰려고 만들었습니다. 그런데 여러분도 쓰시면 좋을거같아요.',
  faqQ4: '돈을 내야하나요?',
  faqA4: '카우노트는 무료 서비스입니다.',
  faqQ5: '투자일지를 어떻게 써야할지 모르겠어요.',
  faqA5: '__[🔗 Cownote  - 투자일지, 어떻게 써야 하나요?](http://bit.ly/cownote-start-guide)(클릭!)__',
  // landing
  landingAppName: 'Cownote',
  landingAppSlogan1: '카우노트는 투자자의 성장을 돕는 투자일지입니다.',
  landingAppSlogan2: '여러분도 카우노트를 통해 좋은 투자자로 성장하고 수익률을 높여보세요.',
  landingCTA: '시작하기',
  landingSection1Header: '좋은 투자자로의 성장',
  landingSection1Subtitle: '주식투자는 누구나 쉽게 시작할 수 있습니다. 그러나 높은 수익률이 그냥 나오지는 않습니다. 좋은 투자자로 성장해야합니다.',
  landingSection2Header: '꾸준히 투자일지 쓰기',
  landingSection2Subtitle: '꾸준히 일지를 쓰며 복기하면 어느새 좋은 투자자로 성장한 자신을 발견할 수 있을거에요. 여러분이 그 과정을 더 쉽게, 그래서 포기하지 않고 꾸준하게 걸어나갈 수 있도록 도와드립니다.',
  landingSection3Header: '투자자를 돕는 투자일지, 카우노트',
  landingSection3Subtitle: '투자일지를 쓰고 복기할 수 있는 더 좋은 방법이 생각나신다면, 언제든지 카우노트에게 말씀해주세요. 저희가 그 도구를 만들어드리겠습니다.',
  landingFeature1: '그 날의 기록을 한눈에 파악할 수 있도록 달력에 이모티콘로 정리',
  landingFeature2: '체계적으로 기록을 할 수 있도록 템플릿을 제공',
  landingFeature3: '자신만의 템플릿으로 투자일지를 작성',
  landingFeature4: '보유하고 있는 자산을 연속적으로 기록하며 추적',
};
