import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TranslateIcon from '@material-ui/icons/Translate';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

interface Locale {
  value: string;
  name: string;
}

const locales: Locale[] = [
  { value: 'en', name: 'English' },
  { value: 'ko', name: '한국어' },
];

const Translation: React.FC = () => {
  const [locale, setLocale] = useState<string>('');
  const [localeAnchorRef, setLocaleAnchorRef] = useState<HTMLElement | null>(null);
  const { i18n } = useTranslation();
  const history = useHistory();

  const handleLocaleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLocaleAnchorRef(event.currentTarget);
  };

  const handleLocaleClose = () => {
    setLocaleAnchorRef(null);
  };

  const handleLocaleChange = (loc: Locale) => () => {
    setLocale(loc.name);
    i18n.changeLanguage(loc.value);
    setLocaleAnchorRef(null);
  };

  useEffect(() => {
    const { lang } = queryString.parse(history.location.search);
    if (!lang) return;
    const currentLocale = locales.find((loc) => loc.value === lang);
    if (!currentLocale) return;
    i18n.changeLanguage(currentLocale.value);
  }, [history.location.search, i18n]);

  return (
    <div>
      <Button
        onClick={handleLocaleClick}
        variant="text"
        color="inherit"
        startIcon={<TranslateIcon />}
      >
        <Hidden smDown>{locale}</Hidden>
        <KeyboardArrowDownIcon fontSize="small" />
      </Button>
      <Menu
        anchorEl={localeAnchorRef}
        keepMounted
        open={Boolean(localeAnchorRef)}
        onClose={handleLocaleClose}
      >
        {locales.map((loc) => (
          <MenuItem
            key={loc.value}
            value={loc.value}
            onClick={handleLocaleChange(loc)}
          >
            {loc.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default Translation;
