import union from 'lodash/union';

import AuthRepository, { User } from '../repositories/authRepository';
import LogRepository, { ListLogsParams, Log, Comment } from '../repositories/logRepository';
import BookmarkRepository, { Bookmark } from '../repositories/bookmarkRepository';
import PreferenceRepository, { Preference } from '../repositories/preferenceRepository';
import StorageRepository from '../repositories/storageRepository';
import LikeRepository, { Like } from '../repositories/likeRepository';

export interface JournalUsecaseParams {
  logRepository: LogRepository;
  bookmarkRepository: BookmarkRepository;
  likeRepository: LikeRepository;
  preferenceRepository: PreferenceRepository,
  authRepository: AuthRepository;
  storageRepository: StorageRepository;
}

export default class JournalUsecase {
  private logRepository: LogRepository;

  private bookmarkRepository: BookmarkRepository;

  private likeRepository: LikeRepository;

  private preferenceRepository: PreferenceRepository;

  private authRepository: AuthRepository;

  private storageRepository: StorageRepository;

  constructor(params: JournalUsecaseParams) {
    this.authRepository = params.authRepository;
    this.preferenceRepository = params.preferenceRepository;
    this.logRepository = params.logRepository;
    this.bookmarkRepository = params.bookmarkRepository;
    this.likeRepository = params.likeRepository;
    this.storageRepository = params.storageRepository;
  }

  private async appendUserToComments(list: Comment[]): Promise<Comment[]> {
    const newList = list.map(async (item): Promise<Comment> => {
      const commentUser = await this.authRepository.getUserFromUID(item.userUID);
      return {
        ...item,
        userDisplayName: commentUser.displayName || commentUser.email?.split('@')[0],
      };
    });
    return Promise.all(newList);
  }

  // TODO: 프로필 기능 추가하기
  private async appendUserToLogs(logs: Log[]): Promise<Log[]> {
    const userUIDs = logs.map((log) => log.userUID);
    await this.authRepository.prefetchUsersFromUIDs(userUIDs);
    const newList = logs.map(async (item): Promise<Log> => {
      const logUser = await this.authRepository.getUserFromUID(item.userUID);
      const comments = await this.appendUserToComments(item.comments || []);
      return {
        ...item,
        userDisplayName: logUser.displayName || logUser.email?.split('@')[0],
        comments,
      };
    });
    return Promise.all(newList);
  }

  public onAuthStateChanged(observer: (user: User | null) => void): firebase.Unsubscribe {
    return this.authRepository.onAuthStateChanged(observer);
  }

  public getCurrentUser() {
    return this.authRepository.getCurrentUser();
  }

  public async getDateFilteredLogs(params: ListLogsParams): Promise<Log[]> {
    const primitive = await this.logRepository.listLogsWithDateFilter(params);
    const list = await this.appendUserToLogs(primitive);
    return list;
  }

  public async getPaginatedLogs(params: ListLogsParams): Promise<Log[]> {
    const primitive = await this.logRepository.pageLogsWithLastVisibleCursor(params);
    const list = await this.appendUserToLogs(primitive);
    return list;
  }

  public async createOrUpdateLog(log: Log, preference: Preference): Promise<void | Log> {
    const created = await this.logRepository.createOrUpdate(log);
    const newPrefTags = union(preference!.tags, log.tags);
    const newPrefEmojis = union(preference?.emojis, [log.emoji]);
    const newPrefCategories = union(preference?.categories, log.categories);
    const newPref: Preference = {
      ...preference,
      tags: newPrefTags,
      emojis: newPrefEmojis,
      categories: newPrefCategories,
    };
    await this.preferenceRepository.createOrUpdate(newPref);
    return created;
  }

  public async getLog(logID: string): Promise<Log> {
    const primitive = await this.logRepository.retrieve(logID);
    const logs = await this.appendUserToLogs([primitive]);
    return logs[0];
  }

  public async deleteLog(logID: string): Promise<void> {
    return this.logRepository.delete(logID);
  }

  public async getUserPreference(userUID: string): Promise<Preference> {
    return this.preferenceRepository.retrieve(userUID);
  }

  public async createComment(comment: Comment, log: Log): Promise<Log> {
    let newLog = await this.logRepository.addComment(comment, log);
    [newLog] = await this.appendUserToLogs([newLog]);
    return newLog;
  }

  public async deleteComment(comment: Comment, log: Log): Promise<Log> {
    let newLog = await this.logRepository.removeComment(comment, log);
    [newLog] = await this.appendUserToLogs([newLog]);
    return newLog;
  }

  public async uploadImage(file: File): Promise<string> {
    return this.storageRepository.uploadUserFile(file);
  }

  public async listBookmarks(userUID: string): Promise<Bookmark[]> {
    return this.bookmarkRepository.list({ userUID });
  }

  public async createBookmark(userUID: string, log: Log): Promise<Bookmark> {
    return this.bookmarkRepository.create({
      logID: log.id!,
      userUID,
      emoji: log.emoji,
      date: log.date,
      preview: log.memo[0].value,
      note: '',
      createdAt: Math.floor(new Date().valueOf() / 1000), // epoch time
    });
  }

  public async listLikes(userUID: string): Promise<Like[]> {
    return this.likeRepository.list({ userUID });
  }

  public async createLike(userUID: string, logID: string): Promise<Like> {
    return this.likeRepository.createOrUpdate({
      userUID,
      logID,
      active: true,
      modifiedAt: Math.floor(new Date().valueOf() / 1000),
    });
  }

  public async deleteLike(userUID: string, logID: string): Promise<Like> {
    return this.likeRepository.createOrUpdate({
      userUID,
      logID,
      active: false,
      modifiedAt: Math.floor(new Date().valueOf() / 1000),
    });
  }

  public async deleteBookmark(id: string): Promise<void> {
    return this.bookmarkRepository.delete(id);
  }
}
