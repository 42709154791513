import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { deepPurple } from '@material-ui/core/colors';

import { GoLinkExternal } from 'react-icons/go';

const useStyle = makeStyles((theme: Theme) => ({
  wrapper: {
    marginTop: 'auto',
    padding: theme.spacing(3, 0),
    backgroundColor: deepPurple[300],
    color: theme.palette.primary.contrastText,
  },
  social: {
    textTransform: 'none',
  },
  content: {
    display: 'flex',
    height: '100%',
    flexGrow: 1,
    alignItems: 'center',
  },
}));

const Footer: React.FC = () => {
  const classes = useStyle();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <Container maxWidth="sm">
          <Grid container>
            <Grid item xs={12} md={4}>
              <Box>
                <a href="https://www.facebook.com/cownote" target="blank">
                  <Button className={classes.social} color="inherit" startIcon={<FacebookIcon />}>
                    Facebook
                  </Button>
                </a>
              </Box>
              <Box>
                <a href="https://www.instagram.com/cownote.app/" target="blank">
                  <Button className={classes.social} color="inherit" startIcon={<InstagramIcon />}>
                    Instagram
                  </Button>
                </a>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box>
                <a href="http://bit.ly/cownote-start-guide" target="blank">
                  <Button color="inherit" size="small" endIcon={<GoLinkExternal />}>
                    {t('startingGuide')}
                  </Button>
                </a>
              </Box>
              <Box>
                <a href="http://bit.ly/cownote-release-note" target="blank">
                  <Button color="inherit" size="small" endIcon={<GoLinkExternal />}>
                    {t('releaseNote')}
                  </Button>
                </a>
              </Box>
              <Box>
                <Link to="/about">
                  <Button color="inherit" size="small">
                    {t('about')}
                  </Button>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box>
                <Link to="/terms">
                  <Button color="inherit" size="small">
                    {t('termsOfUse')}
                  </Button>
                </Link>
              </Box>
              <Box>
                <Link to="/privacy">
                  <Button color="inherit" size="small">
                    {t('privacyPolicy')}
                  </Button>
                </Link>
              </Box>
            </Grid>
          </Grid>
          <Box textAlign="center" marginTop={2}>
            <Typography variant="caption">
              © Cownote
            </Typography>
            <Typography component="div" variant="caption">
              malang@cownote.app
            </Typography>
          </Box>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
