import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { IoLogoGoogle, IoLogoFacebook } from 'react-icons/io';

import useSnackbar from '../hooks/useSnackbar';
import AuthRepository from '../repositories/authRepository';
import useAnonymousRequired from '../hooks/useAnonymousRequired';

interface SignInProps {
  authRepository: AuthRepository
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: theme.spacing(6, 0),
  },
  oauth: {
    textTransform: 'none',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignInContainer: React.FC<SignInProps> = (props) => {
  const { authRepository } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [setSnackbarMessage, renderSnackbar] = useSnackbar();
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  useAnonymousRequired(authRepository, history);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (submitting) return;

    setSubmitting(true);
    try {
      await authRepository.signIn(email, password);
      history.push('/calendar');
    } catch (error) {
      setSnackbarMessage(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSignInWithGoogle = async () => {
    setSubmitting(true);
    try {
      await authRepository.signInWithGoogle();
      history.push('/calendar');
    } catch (error) {
      setSnackbarMessage(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSignInWithFacebook = async () => {
    setSubmitting(true);
    try {
      await authRepository.signInWithFacebook();
      history.push('/calendar');
    } catch (error) {
      setSnackbarMessage(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <Helmet>
        <title>
          {t('cownote')}
          {' - '}
          {t('signIn')}
        </title>
      </Helmet>
      {renderSnackbar()}
      <div className={classes.wrapper}>
        <form onSubmit={handleSubmit}>
          <Typography variant="h4" align="center">
            {t('signIn')}
          </Typography>
          <Box marginTop={3}>
            <Button
              className={classes.oauth}
              variant="outlined"
              color="primary"
              onClick={handleSignInWithGoogle}
              fullWidth
              disabled={submitting}
              startIcon={<IoLogoGoogle />}
            >
              {t('signInWithGoogle')}
            </Button>
          </Box>
          <Box marginTop={2}>
            <Button
              className={classes.oauth}
              variant="outlined"
              color="primary"
              onClick={handleSignInWithFacebook}
              fullWidth
              disabled={submitting}
              startIcon={<IoLogoFacebook />}
            >
              {t('signInWithFacebook')}
            </Button>
          </Box>
          <Box marginTop={3}>
            <Typography component="div" variant="caption" align="center">
              {t('or')}
            </Typography>
          </Box>
          <TextField
            value={email}
            label={t('email')}
            onChange={handleEmailChange}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <TextField
            type="password"
            value={password}
            label={t('password')}
            onChange={handlePasswordChange}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <Button
            className={classes.submit}
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
          >
            {submitting ? <CircularProgress color="inherit" size={22} /> : t('signIn')}
          </Button>
        </form>
        <Box>
          <Link to="/sign_up">
            <Button className={classes.oauth} fullWidth variant="text" color="primary">
              {t('doNotHaveAccount')}
            </Button>
          </Link>
        </Box>
      </div>
    </Container>
  );
};

export default SignInContainer;
