import * as firebase from 'firebase';

export interface Bookmark {
  id?: string;
  logID: string;
  userUID: string;
  emoji: string;
  date: number; // epoch time
  preview: string;
  note: string;
  createdAt: number; // epoch time
}

export interface ListBookmarksParams {
  userUID: string;
}

export default class BookmarkRepository {
  private db: firebase.firestore.Firestore;

  constructor(db: firebase.firestore.Firestore) {
    this.db = db;
  }

  private collection = () => this.db.collection('bookmarks') as firebase.firestore.CollectionReference<Bookmark>;

  public async create(bookmark: Bookmark): Promise<Bookmark> {
    const ref = await this.collection().add(bookmark);
    const doc = await ref.get();
    const data = doc.data() as Bookmark;
    return {
      id: doc.id,
      ...data,
    };
  }

  public async retrieve(id: string): Promise<Bookmark> {
    const doc = await this.collection().doc(id).get();
    const data = doc.data() as Bookmark;
    return {
      id: doc.id,
      ...data,
    };
  }

  public delete(id: string) {
    return this.collection().doc(id).delete();
  }

  public update(id: string, Bookmark: Bookmark) {
    return this.collection().doc(id).update(Bookmark);
  }

  public createOrUpdate(bookmark: Bookmark) {
    if (!bookmark.id) {
      return this.create(bookmark);
    }
    return this.update(bookmark.id, bookmark);
  }

  public async list(params: ListBookmarksParams): Promise<Bookmark[]> {
    const { userUID } = params;
    const query = this.collection().where('userUID', '==', userUID);
    const snapshot = await query.get();
    return snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  }
}
