import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';

import AuthRepository from '../repositories/authRepository';
import useUser from '../hooks/useUser';

interface AuthRequiredProps {
  authRepository: AuthRepository;
}

const useStyle = makeStyles((theme: Theme) => ({
  wrapper: {
    textAlign: 'center',
    margin: theme.spacing(6, 0),
  },
}));

const AuthRequired: React.FC<AuthRequiredProps> = (props) => {
  const { authRepository, children } = props;
  const user = useUser(authRepository);
  const classes = useStyle();
  const { t } = useTranslation();

  if (user === undefined) {
    return (
      <Container maxWidth="xs">
        <div className={classes.wrapper}>
          <CircularProgress size={42} />
        </div>
      </Container>
    );
  }

  if (user === null) {
    return (
      <Container maxWidth="xs">
        <div className={classes.wrapper}>
          <Typography variant="h5">
            {t('authRequired')}
          </Typography>
          <Box marginY={6}>
            <Link to="/sign_in">
              <Button size="large" variant="outlined" color="primary">
                {t('signIn')}
              </Button>
            </Link>
          </Box>
        </div>
      </Container>
    );
  }

  return (
    <>
      {children}
    </>
  );
};

export default AuthRequired;
