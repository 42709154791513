import React, { useState } from 'react';
import format from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import copy from 'copy-to-clipboard';
import ReactMarkdown from 'react-markdown';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LinkIcon from '@material-ui/icons/Link';
import ClearIcon from '@material-ui/icons/Clear';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles, fade, Theme } from '@material-ui/core/styles';
import { ReactComponent as RocketIcon } from '../assets/icons/rocket_secondary.svg';
import { ReactComponent as RocketBorderIcon } from '../assets/icons/rocket_border_gray.svg';

import { User } from '../repositories/authRepository';
import {
  Log,
  Memo,
  Asset,
  Comment,
} from '../repositories/logRepository';
import { getTotalEvaluationAmount, getCurrencySymbol } from '../helpers/assets';
import useSnackbar from '../hooks/useSnackbar';

const useStyle = makeStyles((theme: Theme) => ({
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'top',
    fontSize: 24,
  },
  content: {
    whiteSpace: 'pre-line',
  },
  accentMemoItem: {
    padding: theme.spacing(0.5),
    margin: -theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    backgroundColor: fade(theme.palette.secondary.light, 0.1),
  },
  memoItemKey: {
    fontWeight: 600,
    display: 'inline-block',
    marginRight: theme.spacing(1),
  },
  memoItemImage: {
    width: '100%',
    height: 'auto',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  linkButton: {
    textTransform: 'none',
  },
}));

interface LogItemProps {
  log: Log | null;
  user: User | null;
  accentCategories?: string[];
  onDeleteClick?(id: string): Promise<void>;
  onEditClick?(log: Log): void;
  onBookmarkCreate?(log: Log): Promise<void>;
  onBookmarkDelete?(log: Log): Promise<void>;
  onLikeCreate?(userID: string, logID: string): Promise<void>;
  onLikeDelete?(userID: string, logID: string): Promise<void>;
  onCommentSubmit?(comment: Comment, log: Log): Promise<void>;
  onCommentDelete?(comment: Comment, log: Log): Promise<void>;
  readonly?: boolean;
  bookmarked?: boolean;
  liked?: boolean;
}

type TextFieldEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

const LogItem: React.FC<LogItemProps> = (props) => {
  const {
    onDeleteClick,
    onEditClick,
    onBookmarkCreate,
    onBookmarkDelete,
    onCommentSubmit,
    onCommentDelete,
    onLikeCreate,
    onLikeDelete,
    log,
    user,
    readonly,
    accentCategories,
    bookmarked,
    liked,
  } = props;
  const [menuAnchorRef, setMenuAnchorRef] = useState<HTMLElement | null>(null);
  const [confirming, setConfirming] = useState(false);
  const [commentBody, setCommentBody] = useState<string>('');
  const [progress, setProgress] = useState<boolean>(false);
  const [optLike, setOptLike] = useState<number>(0);
  const classes = useStyle();
  const { t } = useTranslation();
  const [setMessage, renderSnackbar] = useSnackbar();
  const assetsVisible = user?.uid === log?.userUID || log?.publicAssets;
  const logUrl = log ? `cownote.app/feed/${log.id}` : '';
  const likesCount = (log?.likes || 0) + optLike;

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorRef(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorRef(null);
  };

  const handleDeleteClick = async () => {
    setConfirming(true);
  };

  const handleDelete = async () => {
    if (onDeleteClick && log && log.id) {
      await onDeleteClick(log.id);
    }
    setConfirming(false);
    handleMenuClose();
  };

  const handleEditClick = () => {
    if (onEditClick && log) {
      onEditClick(log);
    }
    handleMenuClose();
  };

  const handleCopyLink = () => {
    copy(logUrl);
    setMessage(t('copiedLink'));
  };

  const handleBookmarkCreate = async () => {
    if (!onBookmarkCreate || !log) return;
    setProgress(true);
    await onBookmarkCreate(log);
    setProgress(false);
  };

  const handleBookmarkDelete = async () => {
    if (!onBookmarkDelete || !log) return;
    setProgress(true);
    await onBookmarkDelete(log);
    setProgress(false);
  };

  const handleLikeCreate = async () => {
    if (!onLikeCreate || !log?.id || !user) return;
    setProgress(true);
    setOptLike(optLike + 1);
    await onLikeCreate(user.uid, log.id);
    setProgress(false);
  };

  const handleLikeDelete = async () => {
    if (!onLikeDelete || !log?.id || !user) return;
    setProgress(true);
    setOptLike(optLike - 1);
    await onLikeDelete(user.uid, log.id);
    setProgress(false);
  };

  const handleCommentChange = (event: TextFieldEvent) => {
    setCommentBody(event.target.value);
  };

  const handleCommentSubmit = async () => {
    if (!user || !onCommentSubmit || !log) return;
    setProgress(true);
    const comment: Comment = {
      id: new Date().valueOf(),
      userUID: user.uid,
      body: commentBody,
      createdAt: Math.floor(new Date().valueOf() / 1000),
    };
    await onCommentSubmit(comment, log);
    setCommentBody('');
    setProgress(false);
  };

  const handleCommentDelete = async (comment: Comment) => {
    if (!user || !onCommentDelete || !log) return;
    setProgress(true);
    await onCommentDelete(comment, log);
    setProgress(false);
  };

  const renderAssets = (assets: Asset[]): JSX.Element => (
    <>
      <Typography className={classes.memoItemKey} variant="subtitle2">
        {t('assets')}
      </Typography>
      {assets.map((item) => (
        <div key={item.id}>
          <Typography component="span" variant="body2">
            {`${item.name} ${item.amount} (${t('price')} ${getCurrencySymbol(item)}${numeral(item.price).format('0,0[.]000')})` }
          </Typography>
        </div>
      ))}
      <br />
      <Typography className={classes.memoItemKey} variant="subtitle2">
        {t('evaluationAmount')}
      </Typography>
      {Array.from(getTotalEvaluationAmount(assets).values()).map((item) => (
        <div key={item.currency}>
          <Typography component="span" variant="body2">
            {`${item.currency} ${numeral(item.amount).format('0,0[.]00')}`}
          </Typography>
        </div>
      ))}
      <br />
    </>
  );

  const renderMemo = (memo: Memo): JSX.Element => {
    if (typeof memo === 'string') {
      return <Typography variant="body2">{memo}</Typography>;
    }

    return (
      <>
        {memo.map((item) => (
          <Box
            key={item.id}
            className={
              accentCategories && !!accentCategories.find((c) => c === item.category)
                ? classes.accentMemoItem : undefined
            }
          >
            <Typography className={classes.memoItemKey} variant="subtitle2" component="span">
              {item.key}
            </Typography>
            <Typography className={classes.memoItemKey} variant="caption" component="span">
              {item.category}
            </Typography>
            <Typography variant="body2" component="div">
              <ReactMarkdown
                source={item.value}
                linkTarget="_blank"
              />
            </Typography>
            {item.image && (
              <Card>
                <img className={classes.memoItemImage} src={item.image} alt="Memo item" />
              </Card>
            )}
            <br />
          </Box>
        ))}
      </>
    );
  };

  const renderTags = (tags: string[]): JSX.Element => (
    <Box>
      {tags.map((tag) => (
        <Chip
          key={tag}
          className={classes.chip}
          label={tag}
          size="small"
        />
      ))}
    </Box>
  );

  const renderComments = (comments: Comment[]): JSX.Element => (
    <Box marginTop={2}>
      {comments.map((comment) => (
        <Box key={comment.id} minHeight="1.5rem" display="flex" alignItems="center">
          <Box display="flex">
            <Typography className={classes.memoItemKey} variant="subtitle2" component="span">
              {comment.userDisplayName}
            </Typography>
          </Box>
          <Box display="flex" flexGrow={1}>
            <Typography variant="body2">
              {comment.body}
            </Typography>
          </Box>
          {user && comment.userUID === user.uid && (
            <Box display="flex">
              <IconButton
                size="small"
                onClick={() => handleCommentDelete(comment)}
                disabled={progress}
              >
                <ClearIcon fontSize="small" />
                {/* {progress ? (
                  <CircularProgress color="primary" size={18} />
                ) : (
                  <ClearIcon fontSize="small" />
                )} */}
              </IconButton>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );

  return (
    <div>
      {renderSnackbar()}
      <CardHeader
        avatar={(
          <div className={classes.avatar}>
            {log && log.emoji}
          </div>
        )}
        title={<Typography variant="h6">{log && format(fromUnixTime(log.date), 'MMM d')}</Typography>}
        subheader={<Typography variant="subtitle1">{log && log.userDisplayName}</Typography>}
        action={user && (
          !readonly && (user.uid === log?.userUID) && (
            <IconButton onClick={handleMenuClick}>
              <MoreVertIcon fontSize="small" />
            </IconButton>
          )
        )}
      />
      <Menu
        anchorEl={menuAnchorRef}
        open={Boolean(menuAnchorRef)}
        keepMounted
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEditClick} dense>
          <EditIcon fontSize="small" />
        </MenuItem>
        <MenuItem onClick={handleDeleteClick} dense>
          <DeleteIcon fontSize="small" />
        </MenuItem>
      </Menu>
      <Box className={classes.content} paddingX={2}>
        {assetsVisible && log && log.assets && renderAssets(log.assets)}
        {log && renderMemo(log.memo)}
        {log && log.tags && renderTags(log.tags)}
        {log && log.comments && renderComments(log.comments)}
      </Box>
      <CardActions disableSpacing>
        {optLike >= 0 && (liked || optLike >= 1) ? (
          <IconButton onClick={handleLikeDelete}>
            <RocketIcon width={22} height={22} />
          </IconButton>
        ) : (
          <IconButton onClick={handleLikeCreate}>
            <RocketBorderIcon width={22} height={22} />
          </IconButton>
        )}
        {bookmarked ? (
          <IconButton color="secondary" disabled={progress} onClick={handleBookmarkDelete}>
            <BookmarkIcon fontSize="small" />
          </IconButton>
        ) : (
          <IconButton disabled={progress} onClick={handleBookmarkCreate}>
            <BookmarkBorderIcon fontSize="small" />
          </IconButton>
        )}
      </CardActions>
      <Box paddingX={2} marginTop={-1} marginBottom={2}>
        <Typography variant="body2">
          {`로켓 ${likesCount}개`}
        </Typography>
      </Box>
      <Box paddingX={2} marginBottom={2}>
        <Box>
          {log && log.public && (
            <Button
              className={classes.linkButton}
              variant="text"
              size="small"
              startIcon={<LinkIcon />}
              onClick={handleCopyLink}
            >
              <Typography variant="caption">{logUrl}</Typography>
            </Button>
          )}
        </Box>
      </Box>
      {onCommentSubmit && user && (
        <Box>
          <Divider />
          <Box display="flex" paddingX={2} paddingY={1}>
            <Input
              style={{ flexGrow: 1 }}
              onChange={handleCommentChange}
              fullWidth
              value={commentBody}
              margin="none"
              placeholder={`${t('writeComment')}...`}
              disableUnderline
              multiline
            />
            <Button
              onClick={handleCommentSubmit}
              disabled={commentBody === '' || progress}
            >
              {t('submit')}
              {/* {progress ? (
                <CircularProgress color="inherit" size={22} />
              ) : (
                t('submit')
              )} */}
            </Button>
          </Box>
        </Box>
      )}
      <Dialog
        open={confirming}
        onClose={() => setConfirming(false)}
      >
        <DialogTitle>{t('confirmDelete')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('confirmDeleteVerbose')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirming(false)} color="primary">
            {t('no')}
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            {t('yesAnyway')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LogItem;
