import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/browser';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import firebaseApp from './firebase';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import './index.css';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({ dsn: 'https://081d0d94a1b04fd4864e52d581eb8d50@sentry.io/2847692' });
}

ReactDOM.render(
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <App firebaseApp={firebaseApp} />
  </MuiPickersUtilsProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
