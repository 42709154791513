import * as firebase from 'firebase/app';
import axios from 'axios';

export type User = firebase.User;

export default class AuthRepository {
  private auth: firebase.auth.Auth;

  private funtions = axios.create({ baseURL: 'https://us-central1-trade-log-b2e35.cloudfunctions.net/' });

  private userMap = new Map<string, firebase.UserInfo>();

  constructor(auth: firebase.auth.Auth) {
    this.auth = auth;
  }

  public async signUp(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }

  public async signIn(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  public async signInWithGoogle(): Promise<firebase.auth.UserCredential> {
    const provider = new firebase.auth.GoogleAuthProvider();
    this.auth.useDeviceLanguage();
    return this.auth.signInWithPopup(provider);
  }

  public async signInWithFacebook(): Promise<firebase.auth.UserCredential> {
    const provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope('email');
    this.auth.useDeviceLanguage();
    return this.auth.signInWithPopup(provider);
  }

  public async signOut(): Promise<void> {
    return this.auth.signOut();
  }

  public getCurrentUser(): User | null {
    return this.auth.currentUser;
  }

  public onAuthStateChanged(observer: (user: User | null) => void): firebase.Unsubscribe {
    return this.auth.onAuthStateChanged(observer);
  }

  public updateUserDisplayName(displayName: string): Promise<void> {
    const user = this.getCurrentUser();
    if (!user) {
      throw new Error('there is no currently signed-in user');
    }
    return user.updateProfile({ displayName });
  }

  public getUserFromUID = async (uid: string): Promise<firebase.UserInfo> => {
    let user: firebase.UserInfo;
    if (this.userMap.has(uid)) {
      user = this.userMap.get(uid) as firebase.UserInfo;
    } else {
      const response = await this.funtions.get('getUser', { params: { uid } });
      user = await response.data;
      this.userMap.set(uid, user);
    }
    return user;
  };

  public prefetchUsersFromUIDs = async (UIDs: string[]): Promise<void> => {
    const uids = UIDs.filter((uid) => !this.userMap.has(uid));
    const response = await this.funtions.post('getUsers', { uids });
    const users = await response.data as firebase.UserInfo[];
    users.forEach((user) => this.userMap.set(user.uid, user));
  };
}
