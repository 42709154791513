import React, { useState, Dispatch, SetStateAction } from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useSnackbar = (): [Dispatch<SetStateAction<string>>, () => JSX.Element] => {
  const [message, setMessage] = useState<string>('');

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage('');
  };

  const renderSnackbar = () => (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={message !== ''}
      autoHideDuration={6000}
      onClose={handleClose}
      message={message}
      action={(
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    />
  );

  return [setMessage, renderSnackbar];
};

export default useSnackbar;
