import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyAjFK6NOoMqtch34Njq39CkZ7O1rZLetWk',
  authDomain: 'cownote.app',
  databaseURL: 'https://trade-log-b2e35.firebaseio.com',
  projectId: 'trade-log-b2e35',
  storageBucket: 'trade-log-b2e35.appspot.com',
  messagingSenderId: '660863702252',
  appId: '1:660863702252:web:5a483456eff1176b98c1c9',
  measurementId: 'G-PT38P81HKD',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebaseApp;
