import React, { useCallback } from 'react';

import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, fade, Theme } from '@material-ui/core/styles';

import LogItem from './LogItem';
import LogItemSkeleton from './LogItemSkeleton';

import { User } from '../repositories/authRepository';
import { Log, Comment } from '../repositories/logRepository';
import { Bookmark } from '../repositories/bookmarkRepository';
import { Like } from '../repositories/likeRepository';

interface LogListProps {
  logs: Log[];
  user: User | null;
  bookmarks: Bookmark[];
  likes: Like[];
  accentCategories?: string[];
  onDeleteClick(id: string): Promise<void>;
  onEditClick(log: Log): void;
  onMoreClick(): Promise<void>;
  onBookmarkCreate?(log: Log): Promise<void>;
  onBookmarkDelete?(log: Log): Promise<void>;
  onCommentSubmit?(comment: Comment, log: Log): Promise<void>;
  onCommentDelete?(comment: Comment, log: Log): Promise<void>;
  onLikeCreate?(userID: string, logID: string): Promise<void>;
  onLikeDelete?(userID: string, logID: string): Promise<void>;
  hasMore: boolean;
  fetching: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    margin: theme.spacing(4, 0),
  },
  featuredCard: {
    margin: theme.spacing(4, 0),
    borderColor: fade(theme.palette.primary.light, 0.8),
  },
  button: {
    textAlign: 'center',
    marginTop: '3rem',
    marginBottom: '3rem',
  },
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'top',
    fontSize: 24,
  },
}));

const LogList: React.FC<LogListProps> = (props) => {
  const {
    logs,
    user,
    bookmarks,
    likes,
    accentCategories,
    onDeleteClick,
    onEditClick,
    onMoreClick,
    onBookmarkCreate,
    onBookmarkDelete,
    onCommentSubmit,
    onCommentDelete,
    onLikeCreate,
    onLikeDelete,
    hasMore,
    fetching,
  } = props;
  const classes = useStyles();

  const handleMoreClick = async () => {
    await onMoreClick();
  };

  const handleDeleteClick = async (id: string) => {
    await onDeleteClick(id);
  };

  const handleEditClick = (log: Log) => {
    onEditClick(log);
  };

  const isBookmarked = useCallback((logID: string): boolean => {
    const found = bookmarks.find((bookmark) => bookmark.logID === logID);
    return !!found;
  }, [bookmarks]);

  const isLiked = useCallback((logID: string): boolean => {
    const found = likes.find((like) => like.logID === logID);
    return !!found?.active;
  }, [likes]);

  if (fetching && logs.length === 0) {
    return (
      <div>
        {[1, 2, 3].map((i) => (
          <LogItemSkeleton key={i} />
        ))}
      </div>
    );
  }

  const featured = (userId: string) => userId === 'iO8UTAgs0PM4KVzTREWEEXcFlu42';

  return (
    <div>
      {logs.sort((a, b) => b.date - a.date).map((log: Log) => (
        <Card
          key={log.id}
          className={featured(log.userUID) ? classes.featuredCard : classes.card}
          elevation={0}
          variant="outlined"
        >
          <LogItem
            log={log}
            accentCategories={accentCategories}
            user={user}
            bookmarked={isBookmarked(log.id!)}
            liked={isLiked(log.id!)}
            onDeleteClick={handleDeleteClick}
            onEditClick={handleEditClick}
            onCommentSubmit={onCommentSubmit}
            onCommentDelete={onCommentDelete}
            onBookmarkCreate={onBookmarkCreate}
            onBookmarkDelete={onBookmarkDelete}
            onLikeCreate={onLikeCreate}
            onLikeDelete={onLikeDelete}
          />
        </Card>
      ))}
      {hasMore && (
        <div className={classes.button}>
          <Button variant="contained" color="primary" onClick={handleMoreClick}>
            {fetching ? <CircularProgress size={22} color="inherit" /> : '더 불러오기'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default LogList;
