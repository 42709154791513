import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';

import LogList from './LogList';

import LogRepository, { Log } from '../repositories/logRepository';
import AuthRepository from '../repositories/authRepository';

interface BackofficeProps {
  logRepository: LogRepository;
  authRepository: AuthRepository;
}

const useStyle = makeStyles((theme: Theme) => ({
  wrapper: {
    margin: theme.spacing(3, 0),
  },
}));

const Backoffice: React.FC<BackofficeProps> = (props) => {
  const { logRepository, authRepository } = props;
  const [lastVisible, setLastVisible] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [logs, setLogs] = useState<Log[]>([]);
  const [fetching, setFetching] = useState(false);
  const classes = useStyle();
  const { t } = useTranslation();
  const limit = 10;

  const appendUserToLogs = useCallback(async (list: Log[]): Promise<Log[]> => {
    const newList = list.map(async (item): Promise<Log> => {
      const logUser = await authRepository.getUserFromUID(item.userUID);
      return {
        ...item,
        userDisplayName: logUser.displayName || logUser.email?.split('@')[0],
      };
    });
    return Promise.all(newList);
  }, [authRepository]);

  const fetchInitialLogs = useCallback(async () => {
    setFetching(true);
    const list = await logRepository.pageAllLogsWithLastVisibleCursor({
      limit,
    });
    if (list.length === 0) return;
    setLastVisible(list[list.length - 1].date);
    const listWithUser = await appendUserToLogs(list);
    setLogs(listWithUser);
    setFetching(false);
    if (list.length < limit) {
      setHasMore(false);
    }
  }, [logRepository, appendUserToLogs]);

  const fetchMoreLogs = useCallback(async () => {
    if (fetching) return;
    setFetching(true);
    const list = await logRepository.pageAllLogsWithLastVisibleCursor({
      lastVisible,
      limit,
    });
    if (list.length === 0) return;
    setLastVisible(list[list.length - 1].date);
    const listWithUser = await appendUserToLogs(list);
    setLogs(logs.concat(listWithUser));
    setFetching(false);
    if (list.length < limit) {
      setHasMore(false);
    }
  }, [fetching, lastVisible, logs, logRepository, appendUserToLogs]);

  useEffect(() => {
    fetchInitialLogs();
  }, [fetchInitialLogs]);

  const handleMoreLogClick = async () => {
    await fetchMoreLogs();
  };

  return (
    <div className={classes.wrapper}>
      <Helmet>
        <title>
          {t('cownote')}
          {' - '}
          {t('feed')}
        </title>
      </Helmet>
      <Container maxWidth="sm">
        <Typography component="span" variant="h6">
          {t('feed')}
        </Typography>
        {fetching && <CircularProgress size={22} />}
        <LogList
          logs={logs}
          bookmarks={[]}
          likes={[]}
          user={null}
          onDeleteClick={async () => {}}
          onEditClick={() => {}}
          onMoreClick={handleMoreLogClick}
          hasMore={hasMore}
          fetching={fetching}
        />
      </Container>
    </div>
  );
};

export default Backoffice;
