import * as firebase from 'firebase/app';

export default class StorageRepository {
  private storage: firebase.storage.Storage;

  constructor(storage: firebase.storage.Storage) {
    this.storage = storage;
  }

  public async uploadUserFile(file: File): Promise<string> {
    const timestamp = new Date().valueOf();
    const fileRef = this.storage.ref().child(`users/${timestamp}_${file.name}`);
    await fileRef.put(file);
    return fileRef.getDownloadURL();
  }
}
