import * as firebase from 'firebase';

export interface Like {
  id?: string;
  logID: string;
  userUID: string;
  active: boolean;
  modifiedAt: number; // epoch time
}

export interface ListLikesParams {
  userUID: string;
}

export default class LikeRepository {
  private db: firebase.firestore.Firestore;

  constructor(db: firebase.firestore.Firestore) {
    this.db = db;
  }

  private collection = () => this.db.collection('likes') as firebase.firestore.CollectionReference<Like>;

  public async create(like: Like): Promise<Like> {
    const ref = await this.collection().add(like);
    const doc = await ref.get();
    const data = doc.data() as Like;
    return {
      id: doc.id,
      ...data,
    };
  }

  public async retrieve(id: string): Promise<Like> {
    const doc = await this.collection().doc(id).get();
    const data = doc.data() as Like;
    return {
      id: doc.id,
      ...data,
    };
  }

  public delete(id: string) {
    return this.collection().doc(id).delete();
  }

  public update(id: string, Bookmark: Like) {
    return this.collection().doc(id).update(Bookmark);
  }

  public async createOrUpdate(like: Like) {
    const snapshot = await this.collection()
      .where('userUID', '==', like.userUID)
      .where('logID', '==', like.logID)
      .get();
    if (snapshot.empty) {
      return this.create(like);
    }
    const id = snapshot.docs.map((doc) => doc.id)[0];
    await this.update(id, like);
    return {
      id,
      ...like,
    };
  }

  public async list(params: ListLikesParams): Promise<Like[]> {
    const { userUID } = params;
    const query = this.collection().where('userUID', '==', userUID);
    const snapshot = await query.get();
    return snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  }
}
