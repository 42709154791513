import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import * as firebase from 'firebase/app';
import { importMDX } from 'mdx.macro';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import * as Sentry from '@sentry/browser';
import ErrorBoundary from 'react-error-boundary';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { pink } from '@material-ui/core/colors';

import Welcome from './components/Welcome';
import SignInContainer from './components/SignIn.container';
import SignUpContainer from './components/SignUp.container';
import HeaderContainer from './components/Header.container';
import ProfileContainer from './components/Profile.container';
import AuthRequired from './components/AuthRequired';
import Footer from './components/Footer';
import Backoffice from './components/Backoffice.container';
import Fallback from './components/Fallback';
import SuspenseFallback from './components/Suspense';

import AuthRepository from './repositories/authRepository';
import LogRepository from './repositories/logRepository';
import BookmarkRepository from './repositories/bookmarkRepository';
import LikeRepository from './repositories/likeRepository';
import PreferenceRepository from './repositories/preferenceRepository';
import StorageRepository from './repositories/storageRepository';
import JournalUsecase from './usecase/journalUsecase';
import useUser from './hooks/useUser';

const CalendarContainer = lazy(() => import('./components/Calendar.container'));
const FeedContainer = lazy(() => import('./components/Feed.container'));
const Index = lazy(() => import('./components/Index'));
const FAQ = lazy(() => import('./components/FAQ'));
const AssetContainer = lazy(() => import('./components/Asset.container'));
const TermsOfUse = lazy(() => importMDX('./TermsOfUse.mdx'));
const PrivacyPolicy = lazy(() => importMDX('./PrivacyPolicy.mdx'));

interface AppProps {
  firebaseApp: firebase.app.App;
}

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Noto Sans KR',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#7a57d1',
    },
    secondary: {
      main: pink[400],
    },
  },
});


const App: React.FC<AppProps> = (props) => {
  const { firebaseApp } = props;
  const { t } = useTranslation();

  const authRepository = new AuthRepository(firebaseApp.auth());
  const logRepository = new LogRepository(firebaseApp.firestore());
  const bookmarkRepository = new BookmarkRepository(firebaseApp.firestore());
  const likeRepository = new LikeRepository(firebaseApp.firestore());
  const preferenceRepository = new PreferenceRepository(firebaseApp.firestore());
  const storageRepository = new StorageRepository(firebaseApp.storage());

  const journalUsecase = new JournalUsecase({
    authRepository,
    logRepository,
    bookmarkRepository,
    likeRepository,
    preferenceRepository,
    storageRepository,
  });
  const user = useUser(authRepository);

  const handleError = (error: Error, componentStack: string) => {
    Sentry.withScope((scope) => {
      scope.setExtras({
        componentStack,
        reactAppGitSha: process.env.REACT_APP_GIT_SHA,
      });
      Sentry.captureException(error);
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary FallbackComponent={Fallback} onError={handleError}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${t('cownote')} - ${t('tradingJournal')}`}</title>
          <meta name="description" content={t('cownoteDescription')} />
        </Helmet>
        <main style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Router>
            <HeaderContainer authRepository={authRepository} />
            <Suspense fallback={<SuspenseFallback />}>
              <Switch>
                <Route path="/sign_in">
                  <SignInContainer
                    authRepository={authRepository}
                  />
                </Route>
                <Route path="/sign_up">
                  <SignUpContainer
                    authRepository={authRepository}
                  />
                </Route>
                <Route path="/calendar">
                  <AuthRequired authRepository={authRepository}>
                    <CalendarContainer
                      journalUsecase={journalUsecase}
                    />
                  </AuthRequired>
                </Route>
                <Route exact path="/feed">
                  <FeedContainer
                    journalUsecase={journalUsecase}
                  />
                </Route>
                <Route path="/feed/:id">
                  <FeedContainer
                    journalUsecase={journalUsecase}
                  />
                </Route>
                <Route path="/asset">
                  <AuthRequired authRepository={authRepository}>
                    <AssetContainer
                      authRepository={authRepository}
                      logRepository={logRepository}
                    />
                  </AuthRequired>
                </Route>
                <Route path="/profile">
                  <AuthRequired authRepository={authRepository}>
                    <ProfileContainer
                      authRepository={authRepository}
                    />
                  </AuthRequired>
                </Route>
                <Route path="/terms">
                  <Container maxWidth="sm">
                    <Typography>
                      <TermsOfUse />
                    </Typography>
                  </Container>
                </Route>
                <Route path="/privacy">
                  <Container maxWidth="sm">
                    <Typography>
                      <PrivacyPolicy />
                    </Typography>
                  </Container>
                </Route>
                <Route path="/faq">
                  <FAQ />
                </Route>
                <Route path="/welcome">
                  <Welcome />
                </Route>
                <Route path="/admin">
                  <Backoffice
                    authRepository={authRepository}
                    logRepository={logRepository}
                  />
                </Route>
                <Route path="/about">
                  <Index />
                </Route>
                <Route path="/">
                  {!user ? (
                    <Index />
                  ) : (
                    <CalendarContainer
                      journalUsecase={journalUsecase}
                    />
                  )}
                </Route>
              </Switch>
            </Suspense>
            <Footer />
          </Router>
        </main>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
