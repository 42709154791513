import { useState, useEffect } from 'react';
import AuthRepository, { User } from '../repositories/authRepository';

const useUser = (authRepository: AuthRepository) => {
  const [user, setUser] = useState<User | null | undefined>(undefined);
  useEffect(() => authRepository.onAuthStateChanged(setUser), [authRepository]);
  return user;
};

export default useUser;
