import React from 'react';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Skeleton from '@material-ui/lab/Skeleton';
import CardHeader from '@material-ui/core/CardHeader';

interface LogItemSkeletonProps {
  onlyHeader?: boolean;
}

const LogItemSkeleton: React.FC<LogItemSkeletonProps> = (props) => {
  const { onlyHeader } = props;
  return (
    <Box marginY={4}>
      <Card elevation={0} variant="outlined">
        <CardHeader
          avatar={<Skeleton variant="circle" width={40} height={40} />}
          title={<Skeleton height={10} width="80%" style={{ marginBottom: 6 }} />}
          subheader={<Skeleton height={10} width="40%" />}
        />
        {!onlyHeader && <Skeleton height={340} width="100%" />}
      </Card>
    </Box>
  );
};

export default LogItemSkeleton;
