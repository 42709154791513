export default {
  cownote: 'Cownote',
  cownoteDescription: 'Cownote is a service that helps you become a better traders. The way to be a better traders is to record and look back on my trading. To help you become a good traders, Cownote helps you write a good trading journal.',
  write: 'Write',
  save: 'Save',
  cancel: 'Cancel',
  signIn: 'Sign In',
  signUp: 'Sign Up',
  doNotHaveAccount: 'Don\'t have an account? Sign Up',
  submitSignUp: 'Submit',
  signOut: 'Sign Out',
  checkPassword: 'Please check your password.',
  profile: 'Profile',
  submit: 'Submit',
  add: 'Add',
  asset: 'Asset',
  date: 'Date',
  from: 'From',
  to: 'To',
  writeLog: 'Write Log',
  logs: 'Logs',
  log: 'Log',
  journal: 'Journal',
  tradingJournal: 'Trading Journal',
  feed: 'Feed',
  today: 'Today',
  memo: 'Memo',
  email: 'Email',
  name: 'Name',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  or: 'Or',
  signInWithGoogle: 'Sign In with Google Account',
  signInWithFacebook: 'Sign In with Facebook Account',
  startWithGoogle: 'Start with Google Account',
  startWithFacebook: 'Start with Facebook Account',
  section: 'Section',
  content: 'Content',
  confirmCancel: 'Do you really want to cancel?',
  confirmCancelVerbose: 'You have not saved anything yet. Are you sure?',
  confirmDelete: 'Do you really want to delete?',
  confirmDeleteVerbose: 'Deleted records can not be undone. Are you sure you want to delete?',
  no: 'No',
  yes: 'Yes',
  yesAnyway: 'Yes, it does not matter',
  changeName: 'Change Name',
  latestLog: 'Latest Log',
  noLogWritten: 'No log has been written yet.',
  noBookmarks: 'No bookmark yet',
  authRequired: 'Require sign in to proceed',
  assets: 'Assets',
  assetName: 'Asset Name',
  price: 'Price',
  purchasePrice: 'Purchase Price',
  evaluationAmount: 'Evaluation Amount',
  amount: 'Amount',
  currency: 'Currency',
  termsOfUse: 'Terms of Use',
  privacyPolicy: 'Privacy Policy',
  signUpAgreement: 'By signing up, I agree to the Terms of Use and Privacy Policy.',
  assetsFluctuations: 'Assets Fluctuations',
  totalAssetsFluctuations: 'Total Assets Fluctuations',
  expressTodayTrade: 'Express your trade today with emojis',
  faq: 'FAQ',
  dailyNoteForInvestors: 'Daily note for investors',
  marketState: 'Market State',
  tradingStatus: 'Trading Status',
  remarks: 'Remarks',
  assetInfoAlert: 'There is been an important change in your assets! Write down the reasons and circumstances for these changes in your journal.',
  releaseNote: 'Release Note',
  startingGuide: 'Starting Guide',
  howToStart: 'How to start',
  tags: 'Tags',
  category: 'Category',
  share: 'Share',
  canMakePublic: 'You can make your journal public and share it with others.',
  allowPublicAccess: 'Public access',
  allowPublicAccessToAssets: 'Can read assets',
  publicAccessInfo: 'If you allow public access, it will appear in the other\'s feed, and you can share it through a unique link.',
  copiedLink: 'Copied link',
  showPrivateOnly: 'Show private journal only',
  bookmarks: 'Bookmarks',
  writeComment: 'Write comment',
  about: 'About',
  // FAQ
  faqQ1: 'What is Cownote?',
  faqA1: 'Cownote is a trading journal service that helps people grow into better traders. Keep track of your trades and reflect on what went wrong and what you did well.',
  faqQ2: 'Who made this?',
  faqA2: 'It was made by passing black cow. Moo ~',
  faqQ3: 'Why did you make it?',
  faqA3: 'It made for our use. But I think you should use it too.',
  faqQ4: 'Do I have to pay?',
  faqA4: 'No.',
  faqQ5: 'I have something to suggest.',
  faqA5: 'If you have any idea how to write a better trading journal, feel free to tell us. We will make that idea a tool. \n  ✉️ malang@cownote.app',
  // landing
  landingAppName: 'Cownote',
  landingAppSlogan1: 'Cownote is a trading journal service that helps traders grow.',
  landingAppSlogan2: 'Grow up as a good traders and boost your bottom line with Cownote',
  landingCTA: 'Get Started',
  landingSection1Header: 'Keep your trading journal',
  landingSection1Subtitle: 'Stock investment is easy for anyone to start. But the high yield does not just come out. You have to grow up to be a good trader.',
  landingSection2Header: 'Growing up as a good trader',
  landingSection2Subtitle: 'We help you walk through the progress more easily and steadily without giving up.',
  landingSection3Header: 'Cownote help everyone grow into better trader.',
  landingSection3Subtitle: 'If you have a idea to make Cownote better, please feel free to contact us any time. We will make that tool for you.',
  landingFeature1: 'Emoji on the calendar for a quick view of the day\'s records.',
  landingFeature2: 'Provides templates for systematic recording',
  landingFeature3: 'Create a trade journal with your own template',
  landingFeature4: 'Continuously record and track the assets you have',
};
