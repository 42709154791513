import React from 'react';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

const Suspense: React.FC = () => (
  <Container maxWidth="sm">
    <Box marginTop={12} display="flex" justifyContent="center">
      <CircularProgress size={64} />
    </Box>
  </Container>
);

export default Suspense;
