import isSameDay from 'date-fns/isSameDay';

import { Asset, Currency, Log } from '../repositories/logRepository';

export interface ChartData {
  time: string; // 2019-02-15
  value: number;
}

export interface AssetData {
  [key: string]: ChartData[];
}

export interface EvaluationAmount {
  currency: Currency,
  amount: number;
}

export const CURRENCIES: { name: Currency, symbol: string }[] = [
  {
    name: 'USD',
    symbol: '$',
  },
  {
    name: 'KRW',
    symbol: '￦',
  },
  {
    name: 'BTC',
    symbol: '฿',
  },
];

export const getCurrencySymbol = (asset: Asset): string => {
  const currency = CURRENCIES.find((c) => c.name === asset.currency);
  if (currency) {
    return currency.symbol;
  }
  return '';
};

export const getEvaluationAmount = (asset: Asset): EvaluationAmount => {
  const totalAmount = parseFloat(`${asset.amount}`) * parseFloat(`${asset.price}`);
  if (typeof totalAmount === 'number') {
    return {
      currency: asset.currency,
      amount: Math.round(totalAmount * 100) / 100,
    };
  }

  return {
    currency: asset.currency,
    amount: NaN,
  };
};

export const getTotalEvaluationAmount = (assets: Asset[]): Map<Currency, EvaluationAmount> => {
  const evaluations: Map<Currency, EvaluationAmount> = new Map();
  assets.forEach((asset) => {
    const evaluation = getEvaluationAmount(asset);
    const prev = evaluations.get(asset.currency);
    if (prev) {
      const newEvaluation = {
        currency: evaluation.currency,
        amount: prev.amount + evaluation.amount,
      };
      evaluations.set(newEvaluation.currency, newEvaluation);
    } else {
      evaluations.set(evaluation.currency, evaluation);
    }
  });
  return evaluations;
};

export const getExchangeRate = async () => {
  const response = await fetch('https://blockchain.info/ticker');
  return response.json();
};

export const combineAssetsData = (
  data: AssetData,
  currency: Currency,
  exchangeRate: any,
): ChartData[] => {
  let btcChartData = data.BTC;

  if (currency === '') {
    return btcChartData;
  }

  const usdChartData = data.USD;
  const krwChartData = data.KRW;
  krwChartData.forEach((chart) => {
    const rate = 1 / exchangeRate.KRW['15m'];
    const btcEquivalent = chart.value * rate;
    const newBTCChart = btcChartData.map((btcChart) => {
      if (btcChart.time === chart.time) {
        return {
          time: btcChart.time,
          value: btcChart.value + btcEquivalent,
        };
      }
      return btcChart;
    });
    btcChartData = newBTCChart;
  });

  usdChartData.forEach((chart) => {
    const rate = 1 / exchangeRate.USD['15m'];
    const btcEquivalent = chart.value * rate;
    const newBTCChart = btcChartData.map((btcChart) => {
      if (btcChart.time === chart.time) {
        return {
          time: btcChart.time,
          value: btcChart.value + btcEquivalent,
        };
      }
      return btcChart;
    });
    btcChartData = newBTCChart;
  });

  if (currency === 'BTC') {
    return btcChartData;
  }

  const returnRate = exchangeRate[currency]['15m'];
  return btcChartData.map((chart) => ({
    time: chart.time,
    value: chart.value * returnRate,
  }));
};

export const parseLogAssets = (logs: Log[], end: Date): AssetData => {
  const assetData: AssetData = {
    BTC: [],
    KRW: [],
    USD: [],
  };

  if (logs.length > 0) {
    const startChartDate = new Date(logs[0].date * 1000);
    const endChartDate = end;
    let n = 0;

    let existAsset: any = {
      BTC: null,
      KRW: null,
      USD: null,
    };

    for (
      let currDay = startChartDate;
      currDay < endChartDate;
      currDay = new Date(currDay.setDate(currDay.getDate() + 1))
    ) {
      const log = logs[n];
      if (log && isSameDay(new Date(log.date * 1000), currDay)) {
        n += 1;
        if (log.assets) {
          const newAsset: any = {
            BTC: null,
            KRW: null,
            USD: null,
          };

          // Sum assets for each currency
          log.assets.forEach((asset: Asset) => {
            const value: number = Number(asset.amount) * Number(asset.price);
            if (!value) return;
            if (newAsset[asset.currency]) {
              newAsset[asset.currency] += value;
            } else {
              newAsset[asset.currency] = value;
            }
          });

          // Write to Asset
          Object.keys(newAsset).forEach((currency: any) => {
            if (currency) {
              const chartData: ChartData = {
                time: currDay.toISOString().substring(0, 10),
                value: newAsset[currency],
              };
              assetData[currency].push(chartData);
            }
          });
          existAsset = newAsset;
        }
      } else {
        // Remain all assets
        // eslint-disable-next-line no-loop-func
        Object.keys(existAsset).forEach((currency: any) => {
          if (currency && existAsset[currency]) {
            const chartData: ChartData = {
              time: currDay.toISOString().substring(0, 10),
              value: existAsset[currency],
            };
            assetData[currency].push(chartData);
          }
        });
      }
    }
  }
  return assetData;
};
