import { useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { History } from 'history';

import AuthRepository from '../repositories/authRepository';

const useAnonymousRequired = (authRepository: AuthRepository, history: History, redirectTo = '/calendar') => useEffect(() => {
  const unsubscribe = authRepository.onAuthStateChanged((user) => {
    if (user) {
      history.push(redirectTo);
    }
  });
  return unsubscribe;
}, [authRepository, history, redirectTo]);

export default useAnonymousRequired;
